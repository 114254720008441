import React from 'react'
import axios from 'axios'
import { navigate } from "gatsby"
import RightLineArrowIcon from '../Icons/RightLineArrowIcon'
import { FormControlLabel } from "../Section"
import FormButton from "../Button/FormButton"

class PersonalInformationWhatToExpect extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            source: '',
            page: '',
            form: '',
            name: '',            
            email: '',
            phone_no: '',
            zipcode: '',
            state: '',
            comment: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(event) {
        const {id, value} = event.target
        this.setState({
            [id]: value
        })
    }
    handleSubmit(event) {
        event.preventDefault()
        const { location } = this.props
        const data = {
            source: location.origin,
            page: location.href,
            form: event.target.id,
            first_name: this.state.name,
            email: this.state.email,
            phone_no: this.state.phone_no,
            zipcode: this.state.zipcode,
            state: this.state.state,
            comment: this.state.comment,
            api_token: process.env.GATSBY_FORM_CRM_TOKEN,       
        }
        if (typeof window !== `undefined`) {
			document.getElementById("personalInformationWhatToExpectBtn").setAttribute("disabled", "");
		}
        axios({
          method: "post",
          url: "https://crm.senseicrm.com/api/create-lead-wordpress",
          data: data,
          headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
        }).then((res) => {
            if (typeof dataLayer !== 'undefined') {
                window.dataLayer.push({
                    'event': 'form_submission',
                    'formName': 'PersonalInformationWhatToExpectForm',
                    'enhanced_conversion_data': {
                        'email': this.state.email,
                        'phone_number': this.state.phone_no
                    }
                });
            }
            this.setState({source: '', page: '', form: '', name: '', email: '', phone_no: '', zipcode: '', state: '', comment: ''});
            navigate("/thank-you-what-to-expect");
          }).catch((error) => {
              alert(error)
          });
    }
    render() {
        const { states } = this.props
        return(                           
            <form id="personalformwhatexpect" onSubmit={this.handleSubmit} className="grid">
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="text" id="name" className="form-control" onChange={this.handleChange} pattern="[A-Za-z\s]+$" required/>
                        <FormControlLabel className={this.state.name!==''?"control-label active":"control-label"}>Name</FormControlLabel>
                    </div>
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="email" id="email" className="form-control" onChange={this.handleChange} required/>
                        <FormControlLabel className={this.state.email!==''?"control-label active":"control-label"}>Email Address</FormControlLabel>
                    </div>                
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="tel" id="phone_no" className="form-control" onChange={this.handleChange} pattern="[0-9]{10}" required/>
                        <FormControlLabel className={this.state.phone_no!==''?"control-label active":"control-label"}>Phone Number</FormControlLabel>
                    </div>                
                </div>
                <div className="grid-md-6">
                    <div className="form-group-bordered">
                        <input type="text" id="zipcode" className="form-control" onChange={this.handleChange} pattern="[0-9]+$" required/>
                        <FormControlLabel className={this.state.zipcode!==''?"control-label active":"control-label"}>Zip Code</FormControlLabel>
                    </div>                
                </div>
                <div className="grid-md-6">
                    <div className="form-group-bordered">
                        <select id="state" className="form-control" onChange={this.handleChange} aria-label="state">
                            <option value="">Select State</option>
                            {
                                states.map(item => (
                                    <option 
                                      key={item.node.name}
                                      value={item.node.name}
                                    >
                                     {item.node.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <textarea id="comment" className="form-control" onChange={this.handleChange} required />                        
                        <FormControlLabel className={this.state.comment!==''?"control-label active":"control-label"}>Type your message here…</FormControlLabel>
                    </div>                
                </div>
                <div className="grid-12">
                    <div className="form-action">
                        <FormButton id="personalInformationWhatToExpectBtn" icon={<RightLineArrowIcon />} text="Submit" />
                    </div>
                </div>
            </form>                
	    )
    }
}

export default PersonalInformationWhatToExpect